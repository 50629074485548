<template>
  <base-nav v-model="showMenu" class="navbar-absolute top-navbar" type="white" :transparent="true">
    <div slot="brand" class="navbar-wrapper">
      <div class="navbar-minimize d-inline">
        <sidebar-toggle-button/>
      </div>
      <div class="navbar-toggle d-inline" :class="{ toggled: $sidebar.showSidebar }">
        <button type="button" class="navbar-toggler" @click="toggleSidebar">
          <span class="navbar-toggler-bar bar1"></span>
          <span class="navbar-toggler-bar bar2"></span>
          <span class="navbar-toggler-bar bar3"></span>
        </button>
      </div>
      <base-dropdown v-if="this.$store.state.user.role =='admin'" menu-classes="dropdown-black"
                     title-classes="btn btn-secondary"
                     :title="$store.state.account.name">
        <a class="dropdown-item" href="#/account">{{ $t('navbar.settings') }}</a>
        <!-- <a class="dropdown-item" href="#/organisation">{{ $t('navbar.organizations') }}</a>
        <a class="dropdown-item" @click="openStripeBillingPortal">{{ $t('navbar.billing') }}</a> -->
      </base-dropdown>
      <base-button v-if="this.$store.state.user.role !='admin'" class="dropdown-toggle no-caret btn btn-secondary" data-toggle="dropdown">{{ $store.state.account.name }}</base-button>
      <i class="tim-icons icon-minimal-right ml-0 mr-0"
         style="color: white"></i>
      <base-dropdown menu-classes="dropdown-black"
                     title-classes="btn btn-secondary"
                     :title="$store.getters.getCurrentOrganisation.name">
        <a v-if="this.$store.state.user.role =='admin'" class="dropdown-item" @click="createOrg">
          <i class="tim-icons icon-simple-add" style="color: white"></i> {{ $t('navbar.createOrg') }}
        </a>
        <a v-for="org in otherOrgs" :key="org.id" class="dropdown-item" @click="switchOrg(org.id)">{{ org.name }}</a>
        <div v-if="this.$store.state.user.role =='admin'" class="dropdown-divider"></div>
        <a v-if="this.$store.state.user.role =='admin'" class="dropdown-item" href="#/organisation-settings">{{ $t('navbar.settings') }}</a>
        <a v-if="this.$store.state.user.role =='admin'" class="dropdown-item" href="#/organisation-members">{{ $t('navbar.members') }}</a>
      </base-dropdown>
      <!-- <base-button v-if="this.$store.state.user.role !='admin'" class="dropdown-toggle no-caret btn btn-secondary" data-toggle="dropdown">{{ $store.getters.getCurrentOrganisation.name }}</base-button> -->
      <div class="navbar-brand">{{ $t('navbar.' + routeName.toLowerCase()) }}</div>
    </div>
    <ul class="navbar-nav" :class="$rtl.isRTL ? 'mr-auto' : 'ml-auto'">
      <!-- <base-dropdown tag="li" :menu-on-right="!$rtl.isRTL" title-tag="a" class="nav-item" title-classes="nav-link"
                     menu-classes="dropdown-navbar">
        <template slot="title">
          <div class="d-none d-lg-block d-xl-block"></div>
          <h3>{{ $store.getters.getCurrentOrganisation.tokens_q }} <i class="tim-icons icon-coins"></i></h3>
          <p class="d-lg-none">New Notifications</p>
        </template>
        <li class="nav-link">
          <a href="#" class="nav-item dropdown-item">{{ $t('navbar.availableQueries') }}</a>
        </li>
      </base-dropdown> -->
      <base-dropdown tag="li" :menu-on-right="!$rtl.isRTL" title-tag="a" class="nav-item" title-classes="nav-link"
                     menu-classes="dropdown-navbar">
        <template slot="title">
          <div class="circle">
            <span class="centered-text"><i class="tim-icons icon-single-02"></i></span>
          </div>
          <b class="caret d-none d-lg-block d-xl-block"></b>
          <p class="d-lg-none">Log out</p>
        </template>

        <li class="nav-link" v-if="this.currentLang === 'en'">
          <a @click="changeLang('fr')" class="nav-item dropdown-item">Fr</a>
        </li>
        <li class="nav-link" v-if="this.currentLang === 'fr'">
          <a @click="changeLang('en')" class="nav-item dropdown-item">En</a>
        </li>
        <div v-if="otherOrgs.length > 0" class="dropdown-divider"></div>
        <p v-if="otherOrgs.length > 0" class="dropdown-header" style="color: #00e8b2">{{ $t('navbar.switchOrg') }}</p>
        <a v-for="org in otherOrgs" :key="org.id" class="dropdown-item" @click="switchOrg(org.id)">{{ org.name }}</a>
        <div class="dropdown-divider"></div>
        <li class="nav-link">
          <a @click="logout" href="#" class="nav-item dropdown-item">Log out</a>
        </li>
      </base-dropdown>
    </ul>
  </base-nav>
</template>

<script>
import {BaseNav} from '@/components';
import SidebarToggleButton from './SidebarToggleButton';
import swal from 'sweetalert2';
import {refreshContext} from '@/utils';

export default {
  components: {
    SidebarToggleButton,
    BaseNav,
  },
  computed: {
    routeName() {
      const {name} = this.$route;
      return this.capitalizeFirstLetter(name);
    },
    isRTL() {
      return this.$rtl.isRTL;
    },
    otherOrgs() {
      return this.$store.state.organisations.filter(org => org.id !== this.$store.state.user.current_org_id);
    },
  },
  data() {
    return {
      activeNotifications: false,
      showMenu: false,
      searchModalVisible: false,
      searchQuery: '',
      currentLang: 'en'
    };
  },
  methods: {
    changeLang(lang) {
      this.$i18n.locale = lang;
      this.currentLang = lang;
    },
    logout() {
      localStorage.removeItem('userToken'); // Adjust based on your storage method
      localStorage.removeItem('startDate');
      localStorage.removeItem('endDate');
      localStorage.removeItem('exclusions');
      this.$store.dispatch('logout'); // If using Vuex

      this.$router.push("/login");
    },
    async openStripeBillingPortal() {
      this.token = localStorage.getItem("userToken") || "";
      const fetchOptions = {
        method: "GET",
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      };
      fetch(this.$apiEndpoint + '/api/create_stripe_billing_portal_session', fetchOptions)
        .then(response => {
          if (response.ok) {
            return response.json();
          }
          throw new Error('Network response was not ok.');
        })
        .then(data => {
          const url = data.url;
          if (url) {
            // window.open(url, '_blank');
            window.location.href = url;
          } else {
            console.error('URL not found in the response');
          }
        })
        .catch(error => {
          console.error('There was a problem with your fetch operation:', error);
        });
    },
    async createOrg() {
      const result = await swal.fire({
        title: 'New Organisation Name',
        input: 'text',
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-success btn-fill',
          cancelButton: 'btn btn-danger btn-fill',
        },
        buttonsStyling: false,
      });

      if (result.isConfirmed) {
        const accessToken = localStorage.getItem('userToken');
        const orgItem = {
          name: result.value,
        };

        const response = await fetch(this.$apiEndpoint + '/api/orgs/', {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(orgItem),
        });

        if (response.ok) {
          await refreshContext(this.$store);
          swal.fire({
            icon: 'success',
            title: 'Success',
            text: this.$t('general.orgCreated'),
          });
        } else {
          const errorResponse = await response.json();
          swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: this.$t("errors.backend."+errorResponse.detail),
          });
        }
      }
    },
    async switchOrg(orgId) {
      try {
        await this.$store.dispatch('switchOrganisation', orgId);
        this.$notify({
          message:
            this.$t('general.switchOrg'),
          timeout: 3000,
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'success'
        });
      } catch (error) {
        console.error(error.message);
        // Display an error message to the user using your preferred method
      }
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    toggleNotificationDropDown() {
      this.activeNotifications = !this.activeNotifications;
    },
    closeDropDown() {
      this.activeNotifications = false;
    },
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    hideSidebar() {
      this.$sidebar.displaySidebar(false);
    },
    toggleMenu() {
      this.showMenu = !this.showMenu;
    },
  }
  ,
}
;
</script>

<style scoped>
.top-navbar {
  top: 0;
}

.circle {
  width: 2.5em;
  height: 2.5em;
  border-radius: 50%;
  background-color: #00e8b2;
  display: flex;
  justify-content: center;
  align-items: center;
}

.centered-text {
  text-align: center;
  color: white;
}

.circle:hover {
  text-align: center;
  color: white;
}
</style>
