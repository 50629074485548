import VueRouter from 'vue-router';
import routes from './routes';

// configure router
const router = new VueRouter({
  routes, // short for routes: routes
  linkActiveClass: 'active',
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
});

router.beforeEach((to, from, next) => {
  // Check if the route requires authentication and if the user is not logged in
  if (to.matched.some(record => record.meta.requiresAuth) && !isLoggedIn()) {
    // Redirect to the login page
    next({ path: '/login' });
  } else {
    // Proceed to route
    next();
  }
});

function isLoggedIn() {
  // Implement your logic to check if the user is logged in
  // For example, check for a valid auth token
  return !!localStorage.getItem('userToken');
}

export default router;
