// store.js
import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

// Default state object
const defaultState = {
  account: null,
  user: null,
  organisations: null,
};

// Function to load state from localStorage
function loadState() {
  try {
    const serializedState = localStorage.getItem('vuexState');
    if (serializedState === null) {
      return defaultState; // No state in localStorage, return default state
    }
    return JSON.parse(serializedState); // Parse the serialized state back into an object
  } catch (err) {
    console.error('Could not load state from localStorage:', err);
    return defaultState; // In case of an error, return default state
  }
}

const store = new Vuex.Store({
  state: loadState(),
  mutations: {
    setData(state, data) {
      state.account = data.account;
      state.user = data.user;
      state.organisations = data.orgs;
    },
    logout(state) {
      state.account = null;
      state.user = null;
      state.organisations = null;
    },
    switchOrganisation(state, newOrgId=null) {
      if (newOrgId == null) {
        newOrgId = state.organisations.find(org => org.is_default).id;
      }
      if (state.user && state.organisations.some(org => org.id === newOrgId)) {
        state.user.current_org_id = newOrgId;
      } else {
        throw new Error('Organisation not found');
      }
    }
  },
  actions: {
    logout({commit}) {
      commit('logout');
    },
    fetchData({commit}, data) {
      // You might want to process userData or validate it here
      commit('setData', data);
    },
    switchOrganisation({commit}, newOrgId) {
      commit('switchOrganisation', newOrgId);
    }
  },
  getters: {
    getUserData: state => state.user,
    getOrganisationsData: state => state.organisations,
    getCurrentOrganisation: state => {
      if (!state.user || !state.organisations) return null;
      return state.organisations.find(org => org.id === state.user.current_org_id);
    },
    getOrganisationName: state => orgId => {
      const org = state.organisations.find(org => org.id === orgId)
      if (org) {
        return org.name
      }
      return ""
    }
  }
});


// Subscribe to store updates
store.subscribe((mutation, state) => {
  // Serialize the state
  const serializedState = JSON.stringify(state);
  // Save to localStorage
  localStorage.setItem('vuexState', serializedState);
});


export default store;
