<template>
  <router-view></router-view>
</template>

<script>
export default {
  methods: {
    initializeLayout() {
      if (!this.$rtl.isRTL) {
        // Just make sure rtl css is off when we are not on rtl
        this.$rtl.disableRTL();
      }
    }
  },
  mounted() {
    this.initializeLayout();
  },
  // created() {
  //   const data = localStorage.getItem('data');

  //   if (data) {
  //     this.$store.dispatch('fetchData', JSON.parse(data));
  //   }
  // }
};
</script>

<style>
.grecaptcha-badge { visibility: hidden; }
</style>
